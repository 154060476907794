<style lang="scss">
.text-light {
  li {
    color: var(--mdb-light) !important;
    border-bottom: 0px solid transparent;
  }
}

.text-dark {
  li {
    color: var(--mdb-dark) !important;
    border-bottom: 0px solid transparent;
  }
}

.list-group li:last-child {
  .gradient-line {
    display: none;
  }
}
</style>

<template>
  <div class="card p-3" :class="darkMode">
    <main-title text="Upcoming Events"></main-title>

    <!-- <div class="card" :class="darkMode"> -->
    <calander-list v-if="events" :events="events"></calander-list>
    <!-- </div> -->

    <div class="w-100 d-flex justify-content-center mt-2">
      <p v-if="events.length == 0 && disable == true">No event to show</p>
      <scroll-loader style="padding: 10px;" :loader-method="getEvents" :loader-disable="disable"></scroll-loader>
    </div>
  </div>
</template>

<script>
import { loadEvents } from '@assets/js/dbController';
import { darkModeStyle } from '@assets/js/darkMode';
import { ContentLoader } from "vue-content-loader"
// import  from 'firebase/firestore';

export default {
  components: {
    ContentLoader
  },
  data() {
    return {
      events: [],
      disable: false,
      pageSize: 7,
      lastQuery: null,
      executing: false,
      darkMode: darkModeStyle(),
      darkModeBtn: darkModeStyle('btn-dark', 'btn-light'),
    };
  },
  mounted() {
    this.getEvents();
  },
  methods: {
    async getEvents() {

      if (this.executing == false) {
        this.executing = true;

        const pageParams = {
          lastQuery: this.lastQuery,
          pageSize: this.pageSize,
        }

        const query = await loadEvents(pageParams);

        const mapped = query.response.map(event => {
          const date = event.start.toDate();

          return {
            title: event.name,
            description: event.description,
            date: date,
            url: `/events/${event.id}`
          }
        });

        this.events = [...this.events, ...mapped];
        this.lastQuery = query.lastQuery;

        if (query.response.length < this.pageSize) {
          this.disable = true;
        }

        this.executing = false;
      }

    },
  }
}
</script>